<template>
  <v-hover
      v-slot="{ hover }"
      v-if="file"
  >
    <v-chip
        @click="download"
        :x-small="xSmall"
        :small="small"
        :large="large"
        :x-large="xLarge"
        :class="{'elevation-1': hover}"
        class="px-4 chip-attachment"
        :title="file.name"
    >
      <v-icon
          left
          :x-small="xSmall"
          :small="small"
          :large="large"
          :x-large="xLarge"
      >{{ getIconFile(file) }}</v-icon>

      <span class="dark name--file">{{ file.name}}</span>

      <v-fade-transition>
        <v-overlay v-if="hover" absolute color="primary" opacity=".7">
          <template v-if="!loading">
            <v-icon
                class="mr-2"
                :x-small="xSmall"
                :small="small"
                :large="large"
                :x-large="xLarge"
            >mdi-download</v-icon>
            <span>{{ Units.readableFileSize(file.size) }}</span>
          </template>
          <template v-else>
            <v-progress-circular :size="loaderSize" indeterminate></v-progress-circular>
          </template>
        </v-overlay>
      </v-fade-transition>
    </v-chip>
  </v-hover>
</template>

<script>
import Units from "@/Utils/Units";

export default {
  name: "ChipAttachment",
  props: {
    file: {
      type: Object,
      default() {
        return {
          new_name: "",
          size: 0,
          name: "",
          type: ""
        }
      }
    },
    xSmall: Boolean,
    small: Boolean,
    large: Boolean,
    xLarge: Boolean
  },
  computed: {
    loaderSize() {
      if (this.xSmall)
        return 12
      if (this.small)
        return 16
      if (this.large)
        return 24
      if (this.xLarge)
        return 32
      return 20
    }
  },
  data() {
    return {
      Units,
      loading: false
    }
  },
  methods: {
    async download() {

      // Ajout d'un petit loader avec un temps aléatoire pour la confiance utilisateur
      this.loading = true
      await this.sleep(this.rand(200, 700))
      this.$emit('download')
      this.loading = false

    }
  }
}
</script>

<style scoped>
/*.chip-attachment {*/
/*  width: 100px;*/
/*}*/
/*.name--file{*/
/*  text-overflow: ellipsis;*/
/*  white-space: nowrap;*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*}*/
</style>